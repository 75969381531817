<template>
  <div class="mt-10">
    <div class="vx-row mb-base">
      <div class="vx-col sm:w-6/12 w-full">
        <div class="flex items-center mb-base">
          <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
          <h5 class="font-medium">SPP Kontrak Approval</h5>
        </div>
        <ApprovalProgress
          :type="getApprovalType(spkData.header.jenis_spk)"
          :id-ref="spkData.header.id"/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <div class="flex items-center mb-base">
          <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
          <h5 class="font-medium">SPP Termin Approval</h5>
        </div>
        <p class="text-sm">Silahkan akses tab <strong>Informasi Pembayaran</strong> untuk melihat progress approval pada masing-masing termin.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getSpkApprovalType } from '@/constant/spk-approval-type'

export default {
  name: 'TabApprovals',
  components: {
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  props: {
    isActive: { type: Boolean }
  },
  computed: {
    storeSpkShow () {
      return this.$store.state.proyek.spkShow
    },
    spkData () {
      return this.storeSpkShow.spkData
    }
  },
  methods: {
    getApprovalType (jenisSpk) {
      return getSpkApprovalType(jenisSpk)
    }
  }
}
</script>
